.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.password-field {
    position: relative;
}
/* input[type="password"] {
    padding-right: 30px;
} */
.password {
    padding-right: 30px;
}

.profilePicHover:hover{
    border: 3px solid #3c8dbc;
}

/* #profile_pic:hover{
    border: 3px solid #3c8dbc;
} */
/* .close_button {
    font-size: smaller;
    padding-bottom: 9px;
} */

.profile-pic {
    width: 100px;
    height: 100px;
    background: none;
}