.post-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 30px;
  }
  
  .post-card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 1rem;
  }
  
  .post-content {
    margin-bottom: 1rem;
  }
  
  .post-info {
    display: flex;
    justify-content: space-between;
  }
  
  .post-status {
    padding: 7px 10px;
    border-radius: 4px;
    color: #fff;
  }

  .published {
    background-color: green;
  }

  .draft {
    background-color: red;
  }

  .scheduledForPublish { 
    background-color: #c78b1e;
  }
  
  .post-time {
    color: #ccc;
    font-size: 0.8rem;
  }
  
  .post-actions {
    display: flex;
    gap: 0.5rem;
    color: #aaa;
    cursor: pointer;
  }
  
  .post-actions i:hover {
    color: #333;
  }

  .pointer {
    cursor: pointer
  }
  