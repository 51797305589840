.error-page {
    text-align: center;
    padding: 50px;
    margin: 100px auto;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .error-page-h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .error-page-p {
    color: #888;
    margin-bottom: 10px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buttonHover:hover {
    background-color: #444;
  }