.button {
    color: #C2C7D0 !important;
}

.active-button {
    color: #fff !important;
}

.buttonHover:hover {
    color: #fff !important;
}